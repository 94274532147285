<script setup lang="ts">
import VText from '~/components/ui/VText.vue'

import { capitalize } from '~/mixins/global'

import { useSeoListGeneration } from '~/composables/useSeoListGeneration'
import { useGetTranslatedTagEntityName } from '~/composables/useTagHelpers'

import { useTagStore } from '~/stores/tag'

import { tagPriorities } from '~/types/seoList'

import type Tag from '~/entities/tag'
import type TagParent from '~/entities/tagParent'
import type { SeoListsAsyncData } from '~/types/influencerListTagSeo'
import type {
  ExtendedTagTypeKeys,
  I18nTextKey,
  SeoListing,
} from '~/types/seoList'

interface FormattedList {
  title: string
  formattedTags: { text: string; link: string }[]
}

interface HydratedList extends SeoListing {
  tags: Tag[]
  parents: TagParent[]
}

type Props = {
  urlData?: SeoListsAsyncData['urlData']
  lists?: SeoListing[]
}

const props = withDefaults(defineProps<Props>(), {
  urlData: () => [],
  lists: () => [],
})

const { t } = useI18n()
const {
  getTagTypeFromExtendedTypes,
  generateLink,
  generateTitleFromEntityDescriptors,
} = useSeoListGeneration()
const getTranslatedTagEntityName = useGetTranslatedTagEntityName()

const {
  GET_PARENT_FROM_ID,
  GET_TAG_FROM_ID,
  GET_TAGS_FROM_IDS,
  GET_PARENT_BY_IDS: GET_PARENTS_FROM_IDS,
} = useTagStore()

/**
 * Taking the original list and populating it with Tag and Parent Tags.
 *
 * @returns  A new array containing the additional content.
 */
const hydratedLists = computed<HydratedList[]>(() => {
  if (!props.lists) return [] as HydratedList[]

  return props.lists.reduce<HydratedList[]>((accumulator, listElement) => {
    if (listElement.parent_ids.length + listElement.tag_ids.length > 0)
      accumulator.push({
        ...listElement,
        tags: GET_TAGS_FROM_IDS(listElement.tag_ids) as Tag[],
        parents: GET_PARENTS_FROM_IDS(listElement.parent_ids),
      })

    return accumulator
  }, [])
})
/**
 * Our main computed value, this is what will end up being read in the v-for loop.
 *
 * @returns An a list of objects contianing a title and an array of link + text objects.
 */
const formattedList = computed<FormattedList[]>(() =>
  hydratedLists.value.map((listElement) => {
    const { fixed_type: fixedType, variable_type: variableType } = listElement
    return {
      title: capitalize(
        (
          generateTitle({
            variable_type: variableType,
            fixed_type: fixedType,
          }) as string
        ).trim(),
      ),
      formattedTags: generateFormattedTagList(listElement),
    }
  }),
)

/**
 * This will read the url data (so the data translation of what's in the current URL).
 * And return the coresponding store entity.
 *
 * @param  extendedType - The tag type we are looking for expressed in achraf's extended tag types.
 * @returns| TagParent | undefined} The requested entity or undefined if the supplied argument does not match any url arguments.
 * @example If the url is pop/france and the supplied argument is "genre" the fn will return the pop Tag
 */
function getEntityFromFixedType(
  extendedType: ExtendedTagTypeKeys,
): Tag | TagParent | undefined {
  const narrowedType = getTagTypeFromExtendedTypes(extendedType)
  const details = props.urlData.find((e) => e.tagTypeName === narrowedType)

  if (details) {
    const fetchFn = !details.isParent ? GET_TAG_FROM_ID : GET_PARENT_FROM_ID

    return fetchFn(details.tagId)
  }
  // Thing is if server output is good, this should never fail
  // I just opted for a typesafe return
  return undefined
}

function generateFormattedTagList({
  variable_type: variableType,
  fixed_type: fixedType,
  tags,
  parents,
}: HydratedList): FormattedList['formattedTags'] {
  // check if listing will iterate on parents
  const isParent = parents.length > 0
  // select correct entities based on previous statement
  const entities = isParent ? parents : tags
  // get the fixedType entity, ex: if fixedType is "genre" entity might be the pop Tag entity for example
  const fixedEntity = fixedType ? getEntityFromFixedType(fixedType) : undefined

  // This is base case and is the easiest to handle
  if (!fixedType)
    // Generate links based on entity links
    return entities.map((entity: Tag | TagParent) => {
      const tagType = getTagTypeFromExtendedTypes(variableType)
      const tagName = getTranslatedTagEntityName(
        entity,
        tagType,
        2,
      ).toLowerCase()

      return {
        // Simple text translation here where we fill the {tagName} value of the i18n key
        text:
          tagType === 'subgenre'
            ? t(`components.seo.footer.top`, {
                // The filled in value must of course be translated
                tagName,
              })
            : t('components.seo.footer.topNeedsMusic', { tagName }),
        //  Add the link
        link: generateLink([entity.name]),
      }
    })
  // This is the more complex case includes two types to handle
  else if (fixedEntity && fixedType)
    return entities.map((entity: Tag | TagParent) => {
      // Get the translation of the fixed member
      const tagTypeFixed = getTagTypeFromExtendedTypes(fixedType)
      const tagTypeVariable = getTagTypeFromExtendedTypes(variableType)
      const variableTagName = getTranslatedTagEntityName(
        entity,
        tagTypeVariable,
        2,
      )
      const fixedTagName = getTranslatedTagEntityName(
        fixedEntity,
        tagTypeFixed,
        2,
      )

      // Syntaxic flip is handled in the i18n keys
      // just make sure the url order is respected here as well
      return {
        // Here we generate a i18n key to match the requested text
        // For example if the fixed type is "genre" and the variable type is country
        // We're going to generate "genre_country" as a key
        text: t(
          `components.seo.footer.topWithFixed.${generateSortedI18nKey(
            fixedType,
            variableType,
          )}`,
          {
            // Each key has two arguments reflecting their desired type
            // For example the before mentioned "genre_country" key need a "country" and a "subgenre" argument
            [getTagTypeFromExtendedTypes(variableType)]:
              variableTagName.toLowerCase(),
            [getTagTypeFromExtendedTypes(fixedType)]:
              fixedTagName.toLowerCase(),
          },
        ),
        link:
          // If types are shared then cut it short since dual typed urls are unvalid
          entity.type === fixedEntity.type
            ? generateLink([entity.name])
            : generateLink([fixedEntity.name, entity.name]),
      }
    })
  // This only happens if server failed to provide tags / or if Seo datas are wronged
  else return []
}

/**
 * Generates a i18n key while respecting tag priority orders
 * This is here to make sure we have the leaast amount of translation keys for this
 * Droping from 28'ish combinaison to 3!
 *
 * @param  fixedType - An extended tag type.
 * @param  variableType - An other extended tag type.
 * @returns A valid i18n key.
 */
function generateSortedI18nKey(
  fixedType: ExtendedTagTypeKeys,
  variableType: ExtendedTagTypeKeys,
): I18nTextKey {
  const types = [
    getTagTypeFromExtendedTypes(fixedType),
    getTagTypeFromExtendedTypes(variableType),
  ]
  const scores = types.map((type) => tagPriorities.indexOf(type))

  if (scores[0] - scores[1] >= 0)
    return types.reverse().join('_') as I18nTextKey
  else return types.join('_') as I18nTextKey
}

function generateTitle({
  variable_type: variableType,
  fixed_type: fixedType,
}: Pick<HydratedList, 'variable_type' | 'fixed_type'>): string {
  const entity = fixedType ? getEntityFromFixedType(fixedType) : undefined

  return generateTitleFromEntityDescriptors(
    variableType,
    entity && fixedType
      ? {
          type: fixedType,
          entity,
        }
      : undefined,
  )
}
</script>

<template>
  <div class="tw-w-force-full-grid-system tw-bg-black tw-py-6 sm:tw-py-10">
    <div class="mainParentContainer tw-text-white">
      <div class="listContainer">
        <div
          v-for="({ title, formattedTags }, index) in formattedList"
          :key="index"
          class="tw-grid tw-grid-cols-1 tw-content-start tw-gap-4 tw-text-center sm:tw-text-left"
        >
          <VText cfg="sans/14/medium" color="white">{{ title }}</VText>
          <div class="tw-mx-auto tw-h-px tw-w-10 tw-bg-white sm:tw-mx-0" />
          <div class="tw-grid tw-grid-cols-1 tw-gap-4">
            <VText
              v-for="{ text, link } in formattedTags"
              :key="text"
              cfg="sans/14/regular"
              color="white"
            >
              <NuxtLinkLocale :to="link">{{ text }}</NuxtLinkLocale>
            </VText>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.testCarre {
  @apply tw-h-4 tw-w-4 tw-bg-black;
  @screen sm {
    @apply tw-bg-pink-500;
  }
  @screen 600 {
    @apply tw-bg-orange-500;
  }
}
.listContainer {
  @apply tw-grid tw-grid-cols-1 tw-gap-x-4 tw-gap-y-10;
  @screen sm {
    @apply tw-grid-cols-2;
  }
  @screen 600 {
    @apply tw-grid-cols-4;
  }
}
</style>
